import { emptySplitApi as api } from './emptyApi';
export const addTagTypes = [
  'user',
  'invitation',
  'organization',
  'defaultTemplate',
  'cluster',
  'activityLog',
  'billing',
  'changeset',
  'namespace',
  'serviceAccount',
  'settings',
  'customDomain',
  'invite',
  'keyPair',
  'policy',
  'route',
  'notification',
  'token'
] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes
  })
  .injectEndpoints({
    endpoints: (build) => ({
      generateSubdomainName: build.query<
        GenerateSubdomainNameApiResponse,
        GenerateSubdomainNameApiArg
      >({
        query: () => ({ url: `/generateSubdomainName` })
      }),
      deleteCurrentUser: build.mutation<
        DeleteCurrentUserApiResponse,
        DeleteCurrentUserApiArg
      >({
        query: () => ({ url: `/user`, method: 'DELETE' }),
        invalidatesTags: ['user']
      }),
      updateCurrentUserInfo: build.mutation<
        UpdateCurrentUserInfoApiResponse,
        UpdateCurrentUserInfoApiArg
      >({
        query: () => ({ url: `/user`, method: 'POST' }),
        invalidatesTags: ['user']
      }),
      listUserInvitations: build.query<
        ListUserInvitationsApiResponse,
        ListUserInvitationsApiArg
      >({
        query: () => ({ url: `/user/invitations` }),
        providesTags: ['invitation']
      }),
      acceptInvitation: build.mutation<
        AcceptInvitationApiResponse,
        AcceptInvitationApiArg
      >({
        query: (queryArg) => ({
          url: `/user/invitations/${queryArg}/accept`,
          method: 'POST'
        }),
        invalidatesTags: ['invitation', 'organization']
      }),
      rejectInvitation: build.mutation<
        RejectInvitationApiResponse,
        RejectInvitationApiArg
      >({
        query: (queryArg) => ({
          url: `/user/invitations/${queryArg}/reject`,
          method: 'POST'
        }),
        invalidatesTags: ['invitation']
      }),
      checkIdentityProviderSettings: build.mutation<
        CheckIdentityProviderSettingsApiResponse,
        CheckIdentityProviderSettingsApiArg
      >({
        query: (queryArg) => ({
          url: `/checkIdentityProviderSettings`,
          method: 'POST',
          body: queryArg
        })
      }),
      listDefaultTemplates: build.query<
        ListDefaultTemplatesApiResponse,
        ListDefaultTemplatesApiArg
      >({
        query: (queryArg) => ({ url: `/defaultTemplates/${queryArg}` }),
        providesTags: ['defaultTemplate']
      }),
      startOnboarding: build.mutation<
        StartOnboardingApiResponse,
        StartOnboardingApiArg
      >({
        query: (queryArg) => ({
          url: `/onboarding/start`,
          method: 'POST',
          body: queryArg
        })
      }),
      configureOnboarding: build.mutation<
        ConfigureOnboardingApiResponse,
        ConfigureOnboardingApiArg
      >({
        query: (queryArg) => ({
          url: `/onboarding/configure`,
          method: 'POST',
          body: queryArg
        })
      }),
      completeOnboarding: build.mutation<
        CompleteOnboardingApiResponse,
        CompleteOnboardingApiArg
      >({
        query: () => ({ url: `/onboarding/complete`, method: 'POST' }),
        invalidatesTags: ['user']
      }),
      listOrganizations: build.query<
        ListOrganizationsApiResponse,
        ListOrganizationsApiArg
      >({
        query: () => ({ url: `/organizations` }),
        providesTags: ['organization']
      }),
      createOrganization: build.mutation<
        CreateOrganizationApiResponse,
        CreateOrganizationApiArg
      >({
        query: (queryArg) => ({
          url: `/organizations`,
          method: 'POST',
          body: queryArg
        }),
        invalidatesTags: ['organization', 'cluster']
      }),
      deleteOrganization: build.mutation<
        DeleteOrganizationApiResponse,
        DeleteOrganizationApiArg
      >({
        query: (queryArg) => ({
          url: `/organizations/${queryArg}`,
          method: 'DELETE'
        }),
        invalidatesTags: ['organization']
      }),
      getOrganization: build.query<
        GetOrganizationApiResponse,
        GetOrganizationApiArg
      >({
        query: (queryArg) => ({ url: `/organizations/${queryArg}` }),
        providesTags: ['organization']
      }),
      updateOrganization: build.mutation<
        UpdateOrganizationApiResponse,
        UpdateOrganizationApiArg
      >({
        query: (queryArg) => ({
          url: `/organizations/${queryArg.organizationId}`,
          method: 'PUT',
          body: queryArg.updateOrganizationRequest
        }),
        invalidatesTags: ['organization']
      }),
      listActivityLogs: build.query<
        ListActivityLogsApiResponse,
        ListActivityLogsApiArg
      >({
        query: (queryArg) => ({
          url: `/organizations/${queryArg.organizationId}/activityLogs`,
          params: {
            activityType: queryArg.activityType,
            entityId: queryArg.entityId,
            entityType: queryArg.entityType,
            changesetId: queryArg.changesetId,
            namespaceId: queryArg.namespaceId,
            userId: queryArg.userId,
            offset: queryArg.offset,
            limit: queryArg.limit
          }
        }),
        providesTags: ['activityLog']
      }),
      createBillingPortalSession: build.mutation<
        CreateBillingPortalSessionApiResponse,
        CreateBillingPortalSessionApiArg
      >({
        query: (queryArg) => ({
          url: `/organizations/${queryArg.organizationId}/billing/createBillingPortalSession`,
          method: 'POST',
          body: queryArg.createBillingPortalSessionRequest
        }),
        invalidatesTags: ['billing']
      }),
      createCheckoutSession: build.mutation<
        CreateCheckoutSessionApiResponse,
        CreateCheckoutSessionApiArg
      >({
        query: (queryArg) => ({
          url: `/organizations/${queryArg.organizationId}/billing/createCheckoutSession`,
          method: 'POST',
          body: queryArg.createCheckoutSessionRequest
        }),
        invalidatesTags: ['billing']
      }),
      completeCheckoutSession: build.mutation<
        CompleteCheckoutSessionApiResponse,
        CompleteCheckoutSessionApiArg
      >({
        query: (queryArg) => ({
          url: `/organizations/${queryArg.organizationId}/billing/completeCheckoutSession`,
          method: 'POST',
          body: queryArg.completeCheckoutSessionRequest
        }),
        invalidatesTags: ['billing']
      }),
      listInvoices: build.query<ListInvoicesApiResponse, ListInvoicesApiArg>({
        query: (queryArg) => ({
          url: `/organizations/${queryArg}/billing/invoices`
        }),
        providesTags: ['billing']
      }),
      getPaymentInformation: build.query<
        GetPaymentInformationApiResponse,
        GetPaymentInformationApiArg
      >({
        query: (queryArg) => ({
          url: `/organizations/${queryArg}/billing/paymentInformation`
        }),
        providesTags: ['billing']
      }),
      getSubscriptionInformation: build.query<
        GetSubscriptionInformationApiResponse,
        GetSubscriptionInformationApiArg
      >({
        query: (queryArg) => ({
          url: `/organizations/${queryArg}/billing/subscription`
        }),
        providesTags: ['billing']
      }),
      getBillingUsage: build.query<
        GetBillingUsageApiResponse,
        GetBillingUsageApiArg
      >({
        query: (queryArg) => ({
          url: `/organizations/${queryArg}/billing/usage`
        }),
        providesTags: ['billing']
      }),
      listChangesets: build.query<
        ListChangesetsApiResponse,
        ListChangesetsApiArg
      >({
        query: (queryArg) => ({
          url: `/organizations/${queryArg.organizationId}/changesets`,
          params: {
            clusterId: queryArg.clusterId,
            status: queryArg.status,
            offset: queryArg.offset,
            limit: queryArg.limit
          }
        }),
        providesTags: ['changeset']
      }),
      compareChangesets: build.query<
        CompareChangesetsApiResponse,
        CompareChangesetsApiArg
      >({
        query: (queryArg) => ({
          url: `/organizations/${queryArg.organizationId}/changesets/compare`,
          params: {
            clusterId: queryArg.clusterId,
            firstId: queryArg.firstId,
            secondId: queryArg.secondId
          }
        }),
        providesTags: ['changeset']
      }),
      applyChangeset: build.mutation<
        ApplyChangesetApiResponse,
        ApplyChangesetApiArg
      >({
        query: (queryArg) => ({
          url: `/organizations/${queryArg.organizationId}/changesets/${queryArg.changesetId}/apply`,
          method: 'POST'
        }),
        invalidatesTags: ['changeset']
      }),
      listClusters: build.query<ListClustersApiResponse, ListClustersApiArg>({
        query: (queryArg) => ({ url: `/organizations/${queryArg}/clusters` }),
        providesTags: ['cluster', 'namespace']
      }),
      createCluster: build.mutation<
        CreateClusterApiResponse,
        CreateClusterApiArg
      >({
        query: (queryArg) => ({
          url: `/organizations/${queryArg.organizationId}/clusters`,
          method: 'POST',
          body: queryArg.createClusterRequest
        }),
        invalidatesTags: ['cluster', 'namespace']
      }),
      deleteCluster: build.mutation<
        DeleteClusterApiResponse,
        DeleteClusterApiArg
      >({
        query: (queryArg) => ({
          url: `/organizations/${queryArg.organizationId}/clusters/${queryArg.clusterId}`,
          method: 'DELETE'
        }),
        invalidatesTags: ['cluster', 'namespace']
      }),
      getCluster: build.query<GetClusterApiResponse, GetClusterApiArg>({
        query: (queryArg) => ({
          url: `/organizations/${queryArg.organizationId}/clusters/${queryArg.clusterId}`
        }),
        providesTags: ['cluster', 'namespace']
      }),
      updateCluster: build.mutation<
        UpdateClusterApiResponse,
        UpdateClusterApiArg
      >({
        query: (queryArg) => ({
          url: `/organizations/${queryArg.organizationId}/clusters/${queryArg.clusterId}`,
          method: 'PUT',
          body: queryArg.clusterProperties
        }),
        invalidatesTags: ['cluster', 'namespace']
      }),
      listClusterReplicas: build.query<
        ListClusterReplicasApiResponse,
        ListClusterReplicasApiArg
      >({
        query: (queryArg) => ({
          url: `/organizations/${queryArg.organizationId}/clusters/${queryArg.clusterId}/replicas`,
          params: { startTime: queryArg.startTime, endTime: queryArg.endTime }
        }),
        providesTags: ['cluster']
      }),
      listServiceAccounts: build.query<
        ListServiceAccountsApiResponse,
        ListServiceAccountsApiArg
      >({
        query: (queryArg) => ({
          url: `/organizations/${queryArg.organizationId}/clusters/${queryArg.clusterId}/serviceAccounts`
        }),
        providesTags: ['serviceAccount']
      }),
      createServiceAccount: build.mutation<
        CreateServiceAccountApiResponse,
        CreateServiceAccountApiArg
      >({
        query: (queryArg) => ({
          url: `/organizations/${queryArg.organizationId}/clusters/${queryArg.clusterId}/serviceAccounts`,
          method: 'POST',
          body: queryArg.serviceAccountProperties
        }),
        invalidatesTags: ['serviceAccount', 'changeset', 'activityLog']
      }),
      deleteServiceAccount: build.mutation<
        DeleteServiceAccountApiResponse,
        DeleteServiceAccountApiArg
      >({
        query: (queryArg) => ({
          url: `/organizations/${queryArg.organizationId}/clusters/${queryArg.clusterId}/serviceAccounts/${queryArg.serviceAccountId}`,
          method: 'DELETE'
        }),
        invalidatesTags: ['serviceAccount', 'changeset', 'activityLog']
      }),
      getServiceAccount: build.query<
        GetServiceAccountApiResponse,
        GetServiceAccountApiArg
      >({
        query: (queryArg) => ({
          url: `/organizations/${queryArg.organizationId}/clusters/${queryArg.clusterId}/serviceAccounts/${queryArg.serviceAccountId}`
        }),
        providesTags: ['serviceAccount']
      }),
      getServiceAccountToken: build.query<
        GetServiceAccountTokenApiResponse,
        GetServiceAccountTokenApiArg
      >({
        query: (queryArg) => ({
          url: `/organizations/${queryArg.organizationId}/clusters/${queryArg.clusterId}/serviceAccounts/${queryArg.serviceAccountId}/token`
        }),
        providesTags: ['serviceAccount']
      }),
      getClusterHealth: build.query<
        GetClusterHealthApiResponse,
        GetClusterHealthApiArg
      >({
        query: (queryArg) => ({
          url: `/organizations/${queryArg.organizationId}/clusters/${queryArg.clusterId}/health`
        }),
        providesTags: ['cluster']
      }),
      runHealthChecks: build.mutation<
        RunHealthChecksApiResponse,
        RunHealthChecksApiArg
      >({
        query: (queryArg) => ({
          url: `/organizations/${queryArg.organizationId}/clusters/${queryArg.clusterId}/health`,
          method: 'POST',
          body: queryArg.reRunHealthChecksRequest
        })
      }),
      getDistributionSum: build.query<
        GetDistributionSumApiResponse,
        GetDistributionSumApiArg
      >({
        query: (queryArg) => ({
          url: `/organizations/${queryArg.organizationId}/clusters/${queryArg.clusterId}/distribution/${queryArg.metricId}/sum`,
          params: {
            startTime: queryArg.startTime,
            endTime: queryArg.endTime,
            routeId: queryArg.routeId,
            replicaId: queryArg.replicaId
          }
        })
      }),
      getDistributionTimeSeries: build.query<
        GetDistributionTimeSeriesApiResponse,
        GetDistributionTimeSeriesApiArg
      >({
        query: (queryArg) => ({
          url: `/organizations/${queryArg.organizationId}/clusters/${queryArg.clusterId}/distribution/${queryArg.metricId}/timeSeries`,
          params: {
            percentile: queryArg.percentile,
            startTime: queryArg.startTime,
            endTime: queryArg.endTime,
            routeId: queryArg.routeId,
            replicaId: queryArg.replicaId
          }
        })
      }),
      getTimeSeries: build.query<GetTimeSeriesApiResponse, GetTimeSeriesApiArg>(
        {
          query: (queryArg) => ({
            url: `/organizations/${queryArg.organizationId}/clusters/${queryArg.clusterId}/timeSeries/${queryArg.metricId}`,
            params: {
              startTime: queryArg.startTime,
              endTime: queryArg.endTime,
              routeId: queryArg.routeId,
              replicaId: queryArg.replicaId
            }
          })
        }
      ),
      getTimeSeriesSum: build.query<
        GetTimeSeriesSumApiResponse,
        GetTimeSeriesSumApiArg
      >({
        query: (queryArg) => ({
          url: `/organizations/${queryArg.organizationId}/clusters/${queryArg.clusterId}/timeSeries/${queryArg.metricId}/sum`,
          params: {
            startTime: queryArg.startTime,
            endTime: queryArg.endTime,
            routeId: queryArg.routeId,
            replicaId: queryArg.replicaId
          }
        })
      }),
      pingCluster: build.mutation<PingClusterApiResponse, PingClusterApiArg>({
        query: (queryArg) => ({
          url: `/organizations/${queryArg.organizationId}/clusters/${queryArg.clusterId}/ping`,
          method: 'POST'
        })
      }),
      getSettings: build.query<GetSettingsApiResponse, GetSettingsApiArg>({
        query: (queryArg) => ({
          url: `/organizations/${queryArg.organizationId}/clusters/${queryArg.clusterId}/settings`
        }),
        providesTags: ['settings']
      }),
      updateSettings: build.mutation<
        UpdateSettingsApiResponse,
        UpdateSettingsApiArg
      >({
        query: (queryArg) => ({
          url: `/organizations/${queryArg.organizationId}/clusters/${queryArg.clusterId}/settings`,
          method: 'PUT',
          body: queryArg.settingsProperties
        }),
        invalidatesTags: ['settings', 'changeset', 'activityLog']
      }),
      patchSettings: build.mutation<
        PatchSettingsApiResponse,
        PatchSettingsApiArg
      >({
        query: (queryArg) => ({
          url: `/organizations/${queryArg.organizationId}/clusters/${queryArg.clusterId}/settings`,
          method: 'PATCH',
          body: queryArg.jsonPatch
        }),
        invalidatesTags: ['settings', 'changeset', 'activityLog']
      }),
      rotateClusterToken: build.mutation<
        RotateClusterTokenApiResponse,
        RotateClusterTokenApiArg
      >({
        query: (queryArg) => ({
          url: `/organizations/${queryArg.organizationId}/clusters/${queryArg.clusterId}/token`,
          method: 'POST'
        }),
        invalidatesTags: ['cluster']
      }),
      listCustomDomains: build.query<
        ListCustomDomainsApiResponse,
        ListCustomDomainsApiArg
      >({
        query: (queryArg) => ({
          url: `/organizations/${queryArg.organizationId}/customDomains`,
          params: { clusterId: queryArg.clusterId }
        }),
        providesTags: ['customDomain', 'changeset', 'activityLog']
      }),
      addCustomDomain: build.mutation<
        AddCustomDomainApiResponse,
        AddCustomDomainApiArg
      >({
        query: (queryArg) => ({
          url: `/organizations/${queryArg.organizationId}/customDomains`,
          method: 'POST',
          body: queryArg.customDomainProperties
        }),
        invalidatesTags: ['customDomain', 'changeset', 'activityLog']
      }),
      deleteCustomDomain: build.mutation<
        DeleteCustomDomainApiResponse,
        DeleteCustomDomainApiArg
      >({
        query: (queryArg) => ({
          url: `/organizations/${queryArg.organizationId}/customDomains/${queryArg.customDomainId}`,
          method: 'DELETE'
        }),
        invalidatesTags: ['customDomain', 'changeset', 'activityLog']
      }),
      retryCustomDomain: build.mutation<
        RetryCustomDomainApiResponse,
        RetryCustomDomainApiArg
      >({
        query: (queryArg) => ({
          url: `/organizations/${queryArg.organizationId}/customDomains/${queryArg.customDomainId}/retry`,
          method: 'POST'
        }),
        invalidatesTags: ['customDomain']
      }),
      listOrganizationInvites: build.query<
        ListOrganizationInvitesApiResponse,
        ListOrganizationInvitesApiArg
      >({
        query: (queryArg) => ({
          url: `/organizations/${queryArg}/invitations`
        }),
        providesTags: ['invite']
      }),
      createOrganizationInvite: build.mutation<
        CreateOrganizationInviteApiResponse,
        CreateOrganizationInviteApiArg
      >({
        query: (queryArg) => ({
          url: `/organizations/${queryArg.organizationId}/invitations`,
          method: 'POST',
          body: queryArg.createOrganizationInviteRequest
        }),
        invalidatesTags: ['invite']
      }),
      deleteOrganizationInvite: build.mutation<
        DeleteOrganizationInviteApiResponse,
        DeleteOrganizationInviteApiArg
      >({
        query: (queryArg) => ({
          url: `/organizations/${queryArg.organizationId}/invitations/${queryArg.invitationId}`,
          method: 'DELETE'
        }),
        invalidatesTags: ['invite']
      }),
      listKeyPairs: build.query<ListKeyPairsApiResponse, ListKeyPairsApiArg>({
        query: (queryArg) => ({
          url: `/organizations/${queryArg.organizationId}/keyPairs`,
          params: {
            namespaceId: queryArg.namespaceId,
            includeDescendants: queryArg.includeDescendants
          }
        }),
        providesTags: ['keyPair']
      }),
      createKeyPair: build.mutation<
        CreateKeyPairApiResponse,
        CreateKeyPairApiArg
      >({
        query: (queryArg) => ({
          url: `/organizations/${queryArg.organizationId}/keyPairs`,
          method: 'POST',
          body: queryArg.keyPairWithKeyProperties
        }),
        invalidatesTags: ['keyPair', 'changeset', 'activityLog']
      }),
      deleteKeyPair: build.mutation<
        DeleteKeyPairApiResponse,
        DeleteKeyPairApiArg
      >({
        query: (queryArg) => ({
          url: `/organizations/${queryArg.organizationId}/keyPairs/${queryArg.keyPairId}`,
          method: 'DELETE'
        }),
        invalidatesTags: ['keyPair', 'changeset', 'activityLog']
      }),
      getKeyPair: build.query<GetKeyPairApiResponse, GetKeyPairApiArg>({
        query: (queryArg) => ({
          url: `/organizations/${queryArg.organizationId}/keyPairs/${queryArg.keyPairId}`
        }),
        providesTags: ['keyPair']
      }),
      updateKeyPair: build.mutation<
        UpdateKeyPairApiResponse,
        UpdateKeyPairApiArg
      >({
        query: (queryArg) => ({
          url: `/organizations/${queryArg.organizationId}/keyPairs/${queryArg.keyPairId}`,
          method: 'PUT',
          body: queryArg.keyPairWithKeyProperties
        }),
        invalidatesTags: ['keyPair', 'changeset', 'activityLog']
      }),
      leaveOrganization: build.mutation<
        LeaveOrganizationApiResponse,
        LeaveOrganizationApiArg
      >({
        query: (queryArg) => ({
          url: `/organizations/${queryArg}/leave`,
          method: 'POST'
        }),
        invalidatesTags: ['organization']
      }),
      listNamespaces: build.query<
        ListNamespacesApiResponse,
        ListNamespacesApiArg
      >({
        query: (queryArg) => ({ url: `/organizations/${queryArg}/namespaces` }),
        providesTags: ['namespace']
      }),
      listPolicies: build.query<ListPoliciesApiResponse, ListPoliciesApiArg>({
        query: (queryArg) => ({
          url: `/organizations/${queryArg.organizationId}/policies`,
          params: {
            namespaceId: queryArg.namespaceId,
            includeDescendants: queryArg.includeDescendants
          }
        }),
        providesTags: ['policy']
      }),
      createPolicy: build.mutation<CreatePolicyApiResponse, CreatePolicyApiArg>(
        {
          query: (queryArg) => ({
            url: `/organizations/${queryArg.organizationId}/policies`,
            method: 'POST',
            body: queryArg.policyProperties
          }),
          invalidatesTags: ['policy', 'changeset', 'activityLog']
        }
      ),
      deletePolicy: build.mutation<DeletePolicyApiResponse, DeletePolicyApiArg>(
        {
          query: (queryArg) => ({
            url: `/organizations/${queryArg.organizationId}/policies/${queryArg.policyId}`,
            method: 'DELETE'
          }),
          invalidatesTags: ['policy', 'changeset', 'activityLog']
        }
      ),
      getPolicy: build.query<GetPolicyApiResponse, GetPolicyApiArg>({
        query: (queryArg) => ({
          url: `/organizations/${queryArg.organizationId}/policies/${queryArg.policyId}`
        }),
        providesTags: ['policy']
      }),
      updatePolicy: build.mutation<UpdatePolicyApiResponse, UpdatePolicyApiArg>(
        {
          query: (queryArg) => ({
            url: `/organizations/${queryArg.organizationId}/policies/${queryArg.policyId}`,
            method: 'PUT',
            body: queryArg.policyProperties
          }),
          invalidatesTags: ['policy', 'route', 'changeset', 'activityLog']
        }
      ),
      listRoutes: build.query<ListRoutesApiResponse, ListRoutesApiArg>({
        query: (queryArg) => ({
          url: `/organizations/${queryArg.organizationId}/routes`,
          params: {
            namespaceId: queryArg.namespaceId,
            includeDescendants: queryArg.includeDescendants
          }
        }),
        providesTags: ['route']
      }),
      createRoute: build.mutation<CreateRouteApiResponse, CreateRouteApiArg>({
        query: (queryArg) => ({
          url: `/organizations/${queryArg.organizationId}/routes`,
          method: 'POST',
          body: queryArg.routeProperties
        }),
        invalidatesTags: ['route', 'changeset', 'activityLog']
      }),
      deleteRoute: build.mutation<DeleteRouteApiResponse, DeleteRouteApiArg>({
        query: (queryArg) => ({
          url: `/organizations/${queryArg.organizationId}/routes/${queryArg.routeId}`,
          method: 'DELETE'
        }),
        invalidatesTags: ['route', 'changeset', 'activityLog']
      }),
      getRoute: build.query<GetRouteApiResponse, GetRouteApiArg>({
        query: (queryArg) => ({
          url: `/organizations/${queryArg.organizationId}/routes/${queryArg.routeId}`
        }),
        providesTags: ['route']
      }),
      updateRoute: build.mutation<UpdateRouteApiResponse, UpdateRouteApiArg>({
        query: (queryArg) => ({
          url: `/organizations/${queryArg.organizationId}/routes/${queryArg.routeId}`,
          method: 'PUT',
          body: queryArg.routeProperties
        }),
        invalidatesTags: ['route', 'policy', 'changeset', 'activityLog']
      }),
      getRouteCertificates: build.query<
        GetRouteCertificatesApiResponse,
        GetRouteCertificatesApiArg
      >({
        query: (queryArg) => ({
          url: `/organizations/${queryArg.organizationId}/routes/${queryArg.routeId}/certificates`
        }),
        providesTags: ['route', 'keyPair']
      }),
      listUsersInOrganization: build.query<
        ListUsersInOrganizationApiResponse,
        ListUsersInOrganizationApiArg
      >({
        query: (queryArg) => ({
          url: `/organizations/${queryArg.organizationId}/users`,
          params: { userType: queryArg.userType }
        }),
        providesTags: ['user']
      }),
      createApiAccessUser: build.mutation<
        CreateApiAccessUserApiResponse,
        CreateApiAccessUserApiArg
      >({
        query: (queryArg) => ({
          url: `/organizations/${queryArg.organizationId}/users/apiAccess`,
          method: 'POST',
          body: queryArg.createApiAccessUserRequest
        }),
        invalidatesTags: ['user']
      }),
      removeUserFromOrganization: build.mutation<
        RemoveUserFromOrganizationApiResponse,
        RemoveUserFromOrganizationApiArg
      >({
        query: (queryArg) => ({
          url: `/organizations/${queryArg.organizationId}/users/${queryArg.userId}`,
          method: 'DELETE'
        }),
        invalidatesTags: ['user']
      }),
      renewApiUserRefreshToken: build.mutation<
        RenewApiUserRefreshTokenApiResponse,
        RenewApiUserRefreshTokenApiArg
      >({
        query: (queryArg) => ({
          url: `/organizations/${queryArg.organizationId}/users/${queryArg.userId}/refreshToken`,
          method: 'POST'
        }),
        invalidatesTags: ['user']
      }),
      authorizeNotifications: build.mutation<
        AuthorizeNotificationsApiResponse,
        AuthorizeNotificationsApiArg
      >({
        query: (queryArg) => ({
          url: `/notifications/authorize`,
          method: 'POST',
          body: queryArg
        }),
        invalidatesTags: ['notification']
      }),
      getIdToken: build.mutation<GetIdTokenApiResponse, GetIdTokenApiArg>({
        query: (queryArg) => ({
          url: `/token`,
          method: 'POST',
          body: queryArg
        }),
        invalidatesTags: ['token']
      }),
      getVersion: build.query<GetVersionApiResponse, GetVersionApiArg>({
        query: () => ({ url: `/version` })
      })
    }),
    overrideExisting: false
  });
export { injectedRtkApi as pomeriumApi };
export type GenerateSubdomainNameApiResponse =
  /** status 200 OK */ GenerateSubdomainNameResponse;
export type GenerateSubdomainNameApiArg = void;
export type DeleteCurrentUserApiResponse = unknown;
export type DeleteCurrentUserApiArg = void;
export type UpdateCurrentUserInfoApiResponse = /** status 200 OK */ User;
export type UpdateCurrentUserInfoApiArg = void;
export type ListUserInvitationsApiResponse =
  /** status 200 OK */ ListUserInvitationsResponse;
export type ListUserInvitationsApiArg = void;
export type AcceptInvitationApiResponse = unknown;
export type AcceptInvitationApiArg = /** ID of invitation */ string;
export type RejectInvitationApiResponse = unknown;
export type RejectInvitationApiArg = /** ID of invitation */ string;
export type CheckIdentityProviderSettingsApiResponse =
  /** status 200 OK */ CheckIdentityProviderSettingsResponse;
export type CheckIdentityProviderSettingsApiArg =
  CheckIdentityProviderSettingsRequest;
export type ListDefaultTemplatesApiResponse =
  /** status 200 OK */ ListDefaultTemplatesResponse;
export type ListDefaultTemplatesApiArg =
  /** Type of record */ DefaultTemplateRecordType;
export type StartOnboardingApiResponse =
  /** status 200 OK */ StartOnboardingResponse;
export type StartOnboardingApiArg = StartOnboardingRequest;
export type ConfigureOnboardingApiResponse =
  /** status 200 OK */ ConfigureOnboardingResponse;
export type ConfigureOnboardingApiArg = ConfigureOnboardingRequest;
export type CompleteOnboardingApiResponse = unknown;
export type CompleteOnboardingApiArg = void;
export type ListOrganizationsApiResponse =
  /** status 200 OK */ ListOrganizationsResponse;
export type ListOrganizationsApiArg = void;
export type CreateOrganizationApiResponse =
  /** status 201 Created */ CreateOrganizationResponse;
export type CreateOrganizationApiArg = CreateOrganizationRequest;
export type DeleteOrganizationApiResponse = unknown;
export type DeleteOrganizationApiArg = /** ID of organization */ string;
export type GetOrganizationApiResponse = /** status 200 OK */ Organization;
export type GetOrganizationApiArg = /** ID of organization */ string;
export type UpdateOrganizationApiResponse = /** status 200 OK */ Organization;
export type UpdateOrganizationApiArg = {
  /** ID of organization */
  organizationId: string;
  updateOrganizationRequest: UpdateOrganizationRequest;
};
export type ListActivityLogsApiResponse =
  /** status 200 OK */ ListActivityLogsResponse;
export type ListActivityLogsApiArg = {
  /** ID of organization */
  organizationId: string;
  /** Type of activity */
  activityType?: ActivityType;
  /** ID of entity */
  entityId?: string;
  /** Type of entity */
  entityType?: EntityType;
  /** id of changeset */
  changesetId?: string;
  /** ID of namespace */
  namespaceId?: string;
  /** ID of user */
  userId?: string;
  /** offset of the resources */
  offset?: number;
  /** limit number of resources returned */
  limit?: number;
};
export type CreateBillingPortalSessionApiResponse =
  /** status 200 OK */ CreateBillingPortalSessionResponse;
export type CreateBillingPortalSessionApiArg = {
  /** ID of organization */
  organizationId: string;
  createBillingPortalSessionRequest: CreateBillingPortalSessionRequest;
};
export type CreateCheckoutSessionApiResponse =
  /** status 200 OK */ CreateCheckoutSessionResponse;
export type CreateCheckoutSessionApiArg = {
  /** ID of organization */
  organizationId: string;
  createCheckoutSessionRequest: CreateCheckoutSessionRequest;
};
export type CompleteCheckoutSessionApiResponse = unknown;
export type CompleteCheckoutSessionApiArg = {
  /** ID of organization */
  organizationId: string;
  completeCheckoutSessionRequest: CompleteCheckoutSessionRequest;
};
export type ListInvoicesApiResponse = /** status 200 OK */ ListInvoicesResponse;
export type ListInvoicesApiArg = /** ID of organization */ string;
export type GetPaymentInformationApiResponse =
  /** status 200 OK */ PaymentInformation;
export type GetPaymentInformationApiArg = /** ID of organization */ string;
export type GetSubscriptionInformationApiResponse =
  /** status 200 OK */ SubscriptionInformation;
export type GetSubscriptionInformationApiArg = /** ID of organization */ string;
export type GetBillingUsageApiResponse = /** status 200 OK */ BillingUsage;
export type GetBillingUsageApiArg = /** ID of organization */ string;
export type ListChangesetsApiResponse =
  /** status 200 OK */ ListChangesetsResponse;
export type ListChangesetsApiArg = {
  /** ID of organization */
  organizationId: string;
  /** ID of cluster */
  clusterId?: string;
  /** status of changeset */
  status?: ChangesetStatus;
  /** offset of the resources */
  offset?: number;
  /** limit number of resources returned */
  limit?: number;
};
export type CompareChangesetsApiResponse =
  /** status 200 OK */ CompareChangesetsResponse;
export type CompareChangesetsApiArg = {
  /** ID of organization */
  organizationId: string;
  /** ID of cluster */
  clusterId: string;
  /** id of the first changeset to compare */
  firstId?: string;
  /** id of the second changeset to compare */
  secondId?: string;
};
export type ApplyChangesetApiResponse = /** status 200 OK */ Changeset;
export type ApplyChangesetApiArg = {
  /** ID of organization */
  organizationId: string;
  /** ID of changeset */
  changesetId: string;
};
export type ListClustersApiResponse = /** status 200 OK */ ListClustersResponse;
export type ListClustersApiArg = /** ID of organization */ string;
export type CreateClusterApiResponse =
  /** status 201 Created */ CreateClusterResponse;
export type CreateClusterApiArg = {
  /** ID of organization */
  organizationId: string;
  createClusterRequest: CreateClusterRequest;
};
export type DeleteClusterApiResponse = unknown;
export type DeleteClusterApiArg = {
  /** ID of organization */
  organizationId: string;
  /** ID of cluster */
  clusterId: string;
};
export type GetClusterApiResponse = /** status 200 OK */ Cluster;
export type GetClusterApiArg = {
  /** ID of organization */
  organizationId: string;
  /** ID of cluster */
  clusterId: string;
};
export type UpdateClusterApiResponse = /** status 200 OK */ Cluster;
export type UpdateClusterApiArg = {
  /** ID of organization */
  organizationId: string;
  /** ID of cluster */
  clusterId: string;
  clusterProperties: ClusterProperties;
};
export type ListClusterReplicasApiResponse =
  /** status 200 OK */ ListClusterReplicasResponse;
export type ListClusterReplicasApiArg = {
  /** ID of organization */
  organizationId: string;
  /** ID of cluster */
  clusterId: string;
  /** Start time of the time range */
  startTime: string;
  /** Start time of the time range */
  endTime: string;
};
export type ListServiceAccountsApiResponse =
  /** status 200 OK */ ListServiceAccountsResponse;
export type ListServiceAccountsApiArg = {
  /** ID of organization */
  organizationId: string;
  /** ID of cluster */
  clusterId: string;
};
export type CreateServiceAccountApiResponse =
  /** status 201 Created */ CreateServiceAccountResponse;
export type CreateServiceAccountApiArg = {
  /** ID of organization */
  organizationId: string;
  /** ID of cluster */
  clusterId: string;
  serviceAccountProperties: ServiceAccountProperties;
};
export type DeleteServiceAccountApiResponse = unknown;
export type DeleteServiceAccountApiArg = {
  /** ID of organization */
  organizationId: string;
  /** ID of cluster */
  clusterId: string;
  /** ID of service account */
  serviceAccountId: string;
};
export type GetServiceAccountApiResponse = /** status 200 OK */ ServiceAccount;
export type GetServiceAccountApiArg = {
  /** ID of organization */
  organizationId: string;
  /** ID of cluster */
  clusterId: string;
  /** ID of service account */
  serviceAccountId: string;
};
export type GetServiceAccountTokenApiResponse =
  /** status 200 OK */ ServiceAccountToken;
export type GetServiceAccountTokenApiArg = {
  /** ID of organization */
  organizationId: string;
  /** ID of cluster */
  clusterId: string;
  /** ID of service account */
  serviceAccountId: string;
};
export type GetClusterHealthApiResponse =
  /** status 200 OK */ GetClusterHealthResponse;
export type GetClusterHealthApiArg = {
  /** ID of organization */
  organizationId: string;
  /** ID of cluster */
  clusterId: string;
};
export type RunHealthChecksApiResponse = unknown;
export type RunHealthChecksApiArg = {
  /** ID of organization */
  organizationId: string;
  /** ID of cluster */
  clusterId: string;
  reRunHealthChecksRequest: ReRunHealthChecksRequest;
};
export type GetDistributionSumApiResponse =
  /** status 200 OK */ GetDistributionMetricSumResponse;
export type GetDistributionSumApiArg = {
  /** ID of cluster */
  clusterId: string;
  /** ID of organization */
  organizationId: string;
  /** ID of distribution metric */
  metricId: DistributionMetricId;
  /** Start time of the time range */
  startTime: string;
  /** Start time of the time range */
  endTime: string;
  /** ID of route */
  routeId?: string;
  /** ID of replica */
  replicaId?: string;
};
export type GetDistributionTimeSeriesApiResponse =
  /** status 200 OK */ GetDistributionMetricTimeSeriesResponse;
export type GetDistributionTimeSeriesApiArg = {
  /** ID of cluster */
  clusterId: string;
  /** ID of organization */
  organizationId: string;
  /** ID of distribution metric */
  metricId: DistributionMetricId;
  /** Percentile of the distribution */
  percentile?: Percentile;
  /** Start time of the time range */
  startTime: string;
  /** Start time of the time range */
  endTime: string;
  /** ID of route */
  routeId?: string;
  /** ID of replica */
  replicaId?: string;
};
export type GetTimeSeriesApiResponse =
  /** status 200 OK */ GetTimeSeriesResponse;
export type GetTimeSeriesApiArg = {
  /** ID of cluster */
  clusterId: string;
  /** ID of organization */
  organizationId: string;
  /** ID of cluster time series metric */
  metricId: TimeSeriesMetricId;
  /** Start time of the time range */
  startTime: string;
  /** Start time of the time range */
  endTime: string;
  /** ID of route */
  routeId?: string;
  /** ID of replica */
  replicaId?: string;
};
export type GetTimeSeriesSumApiResponse =
  /** status 200 OK */ GetTimeSeriesSumResponse;
export type GetTimeSeriesSumApiArg = {
  /** ID of cluster */
  clusterId: string;
  /** ID of organization */
  organizationId: string;
  /** ID of cluster time series metric */
  metricId: TimeSeriesMetricId;
  /** Start time of the time range */
  startTime: string;
  /** Start time of the time range */
  endTime: string;
  /** ID of route */
  routeId?: string;
  /** ID of replica */
  replicaId?: string;
};
export type PingClusterApiResponse = /** status 200 OK */ PingClusterResponse;
export type PingClusterApiArg = {
  /** ID of organization */
  organizationId: string;
  /** ID of cluster */
  clusterId: string;
};
export type GetSettingsApiResponse = /** status 200 OK */ Settings;
export type GetSettingsApiArg = {
  /** ID of organization */
  organizationId: string;
  /** ID of cluster */
  clusterId: string;
};
export type UpdateSettingsApiResponse = /** status 200 OK */ Settings;
export type UpdateSettingsApiArg = {
  /** ID of organization */
  organizationId: string;
  /** ID of cluster */
  clusterId: string;
  settingsProperties: SettingsProperties;
};
export type PatchSettingsApiResponse = /** status 200 OK */ Settings;
export type PatchSettingsApiArg = {
  /** ID of organization */
  organizationId: string;
  /** ID of cluster */
  clusterId: string;
  jsonPatch: JsonPatch;
};
export type RotateClusterTokenApiResponse =
  /** status 200 OK */ RefreshTokenProperties;
export type RotateClusterTokenApiArg = {
  /** ID of organization */
  organizationId: string;
  /** ID of cluster */
  clusterId: string;
};
export type ListCustomDomainsApiResponse =
  /** status 200 OK */ ListCustomDomainsResponse;
export type ListCustomDomainsApiArg = {
  /** ID of organization */
  organizationId: string;
  /** ID of cluster */
  clusterId: string;
};
export type AddCustomDomainApiResponse = /** status 201 Created */ CustomDomain;
export type AddCustomDomainApiArg = {
  /** ID of organization */
  organizationId: string;
  customDomainProperties: CustomDomainProperties;
};
export type DeleteCustomDomainApiResponse = unknown;
export type DeleteCustomDomainApiArg = {
  /** ID of organization */
  organizationId: string;
  /** ID of custom domain */
  customDomainId: string;
};
export type RetryCustomDomainApiResponse = unknown;
export type RetryCustomDomainApiArg = {
  /** ID of organization */
  organizationId: string;
  /** ID of custom domain */
  customDomainId: string;
};
export type ListOrganizationInvitesApiResponse =
  /** status 200 OK */ ListOrganizationInvitesResponse;
export type ListOrganizationInvitesApiArg = /** ID of organization */ string;
export type CreateOrganizationInviteApiResponse =
  /** status 201 Created */ CreateOrganizationInviteResponse;
export type CreateOrganizationInviteApiArg = {
  /** ID of organization */
  organizationId: string;
  createOrganizationInviteRequest: CreateOrganizationInviteRequest;
};
export type DeleteOrganizationInviteApiResponse = unknown;
export type DeleteOrganizationInviteApiArg = {
  /** ID of organization */
  organizationId: string;
  /** ID of invitation */
  invitationId: string;
};
export type ListKeyPairsApiResponse = /** status 200 OK */ ListKeyPairsResponse;
export type ListKeyPairsApiArg = {
  /** ID of organization */
  organizationId: string;
  /** ID of namespace */
  namespaceId: string;
  /** include resources from descendant namespaces */
  includeDescendants?: boolean;
};
export type CreateKeyPairApiResponse =
  /** status 201 Created */ KeyPairWithCertificateInfo;
export type CreateKeyPairApiArg = {
  /** ID of organization */
  organizationId: string;
  keyPairWithKeyProperties: KeyPairWithKeyProperties;
};
export type DeleteKeyPairApiResponse = unknown;
export type DeleteKeyPairApiArg = {
  /** ID of organization */
  organizationId: string;
  /** ID of namespace */
  keyPairId: string;
};
export type GetKeyPairApiResponse =
  /** status 200 OK */ KeyPairWithCertificateInfo;
export type GetKeyPairApiArg = {
  /** ID of organization */
  organizationId: string;
  /** ID of namespace */
  keyPairId: string;
};
export type UpdateKeyPairApiResponse =
  /** status 200 OK */ KeyPairWithCertificateInfo;
export type UpdateKeyPairApiArg = {
  /** ID of organization */
  organizationId: string;
  /** ID of namespace */
  keyPairId: string;
  keyPairWithKeyProperties: KeyPairWithKeyProperties;
};
export type LeaveOrganizationApiResponse = unknown;
export type LeaveOrganizationApiArg = /** ID of organization */ string;
export type ListNamespacesApiResponse =
  /** status 200 OK */ ListNamespacesResponse;
export type ListNamespacesApiArg = /** ID of organization */ string;
export type ListPoliciesApiResponse = /** status 200 OK */ ListPoliciesResponse;
export type ListPoliciesApiArg = {
  /** ID of organization */
  organizationId: string;
  /** ID of namespace */
  namespaceId: string;
  /** include resources from descendant namespaces */
  includeDescendants?: boolean;
};
export type CreatePolicyApiResponse = /** status 201 Created */ Policy;
export type CreatePolicyApiArg = {
  /** ID of organization */
  organizationId: string;
  policyProperties: PolicyProperties;
};
export type DeletePolicyApiResponse = unknown;
export type DeletePolicyApiArg = {
  /** ID of organization */
  organizationId: string;
  /** ID of policy */
  policyId: string;
};
export type GetPolicyApiResponse = /** status 200 OK */ Policy;
export type GetPolicyApiArg = {
  /** ID of organization */
  organizationId: string;
  /** ID of policy */
  policyId: string;
};
export type UpdatePolicyApiResponse = /** status 200 OK */ Policy;
export type UpdatePolicyApiArg = {
  /** ID of organization */
  organizationId: string;
  /** ID of policy */
  policyId: string;
  policyProperties: PolicyProperties;
};
export type ListRoutesApiResponse = /** status 200 OK */ ListRoutesResponse;
export type ListRoutesApiArg = {
  /** ID of organization */
  organizationId: string;
  /** ID of namespace */
  namespaceId: string;
  /** include resources from descendant namespaces */
  includeDescendants?: boolean;
};
export type CreateRouteApiResponse = /** status 201 Created */ Route;
export type CreateRouteApiArg = {
  /** ID of organization */
  organizationId: string;
  routeProperties: RouteProperties;
};
export type DeleteRouteApiResponse = unknown;
export type DeleteRouteApiArg = {
  /** ID of organization */
  organizationId: string;
  /** ID of route */
  routeId: string;
};
export type GetRouteApiResponse = /** status 200 OK */ Route;
export type GetRouteApiArg = {
  /** ID of organization */
  organizationId: string;
  /** ID of route */
  routeId: string;
};
export type UpdateRouteApiResponse = /** status 200 OK */ Route;
export type UpdateRouteApiArg = {
  /** ID of organization */
  organizationId: string;
  /** ID of route */
  routeId: string;
  routeProperties: RouteProperties;
};
export type GetRouteCertificatesApiResponse =
  /** status 200 OK */ ListKeyPairsResponse;
export type GetRouteCertificatesApiArg = {
  /** ID of organization */
  organizationId: string;
  /** ID of route */
  routeId: string;
};
export type ListUsersInOrganizationApiResponse =
  /** status 200 OK */ ListUsersInOrganizationResponse;
export type ListUsersInOrganizationApiArg = {
  /** ID of organization */
  organizationId: string;
  /** Type of user */
  userType?: UserType;
};
export type CreateApiAccessUserApiResponse =
  /** status 201 OK */ CreateApiAccessUserResponse;
export type CreateApiAccessUserApiArg = {
  /** ID of organization */
  organizationId: string;
  createApiAccessUserRequest: CreateApiAccessUserRequest;
};
export type RemoveUserFromOrganizationApiResponse = unknown;
export type RemoveUserFromOrganizationApiArg = {
  /** ID of organization */
  organizationId: string;
  /** ID of user */
  userId: string;
};
export type RenewApiUserRefreshTokenApiResponse =
  /** status 200 OK */ RefreshTokenProperties;
export type RenewApiUserRefreshTokenApiArg = {
  /** ID of organization */
  organizationId: string;
  /** ID of user */
  userId: string;
};
export type AuthorizeNotificationsApiResponse = /** status 200 OK */ {
  auth: string;
};
export type AuthorizeNotificationsApiArg = {
  socket_id: string;
  channel_name: string;
};
export type GetIdTokenApiResponse = /** status 200 OK */ GetTokenResponse;
export type GetIdTokenApiArg = RefreshTokenProperties;
export type GetVersionApiResponse = /** status 200 OK */ GetVersionResponse;
export type GetVersionApiArg = void;
export type GenerateSubdomainNameResponse = {
  name: string;
};
export type ObjectStandardProperties = {
  id: string;
  createdAt: string;
  updatedAt: string;
};
export type UserType = 'user_type_interactive' | 'user_type_api_access';
export type UserProperties = {
  email: string;
  displayName?: string;
  needsOnboarding: boolean;
  photoUrl?: string;
  type: UserType;
};
export type User = ObjectStandardProperties & UserProperties;
export type UserIdentityInfo = {
  email?: string;
  displayName?: string;
  photoUrl?: string;
};
export type OrganizationProperties = {
  name: string;
  /** URL to an image that will be used as the organization logo.
    User may provide a URL to an image hosted on a third party service,
    or upload an image to the dashboard, which would result in an URL being generated.
     */
  logoURL?: string;
};
export type OrganizationType = 'personal' | 'professional';
export type OrganizationRole = 'owner' | 'admin' | 'auditor' | 'member';
export type Quotas = {
  administrators: number;
  apiUsers: number;
  certificates: number;
  clusters: number;
  customDomains: number;
  policies: number;
  replicas: number;
  routes: number;
  serviceAccounts: number;
};
export type OrganizationComputedProperties = {
  organizationType: OrganizationType;
  ownerUserId: string;
  role: OrganizationRole;
  joinedAt: string;
  quotas: Quotas;
  readOnly: boolean;
};
export type Organization = ObjectStandardProperties &
  OrganizationProperties &
  OrganizationComputedProperties;
export type UserInvitationProperties = {
  invitedBy: UserIdentityInfo;
  organization: Organization;
  organizationRole: OrganizationRole;
};
export type UserInvitation = ObjectStandardProperties &
  UserInvitationProperties;
export type ListUserInvitationsResponse = UserInvitation[];
export type CheckIdentityProviderSettingsResponseErrors = {
  provider?: string;
  url?: string;
  clientId?: string;
  clientSecret?: string;
  requestParams?: string;
  scopes?: string;
};
export type CheckIdentityProviderSettingsResponse = {
  success: boolean;
  errors?: CheckIdentityProviderSettingsResponseErrors;
};
export type IdentityProviderType =
  | 'apple'
  | 'auth0'
  | 'azure'
  | 'cognito'
  | 'github'
  | 'gitlab'
  | 'google'
  | 'oidc'
  | 'okta'
  | 'onelogin'
  | 'ping';
export type StringMap = {
  [key: string]: string;
};
export type StringList = string[];
export type CheckIdentityProviderSettingsRequest = {
  provider: IdentityProviderType;
  url?: string;
  clientId?: string;
  clientSecret?: string;
  requestParams?: StringMap;
  scopes?: StringList;
};
export type DefaultTemplateRecordType = 'route' | 'settings' | 'policy';
export type DefaultTemplateProperties = {
  recordType: DefaultTemplateRecordType;
  name: string;
  defaultProperties: {
    [key: string]: any;
  };
};
export type DefaultTemplate = ObjectStandardProperties &
  DefaultTemplateProperties;
export type ListDefaultTemplatesResponse = DefaultTemplate[];
export type StartOnboardingResponse = {
  organizationId: string;
  clusterId: string;
  clusterToken: string;
};
export type StartOnboardingRequest = {
  system: string;
  timezone: string;
};
export type ConfigureOnboardingResponse = object;
export type IpAddress = string;
export type Port = string;
export type ConfigureOnboardingRequest = {
  ipAddress?: IpAddress;
  port?: Port;
  system: string;
  timezone: string;
};
export type ListOrganizationsResponse = Organization[];
export type ClusterProperties = {
  name: string;
  manualOverrideIpAddress?: IpAddress;
};
export type ImportHints = {
  systemType?: string;
  hostname?: string;
  kubernetesNamespace?: string;
  argv0?: string;
  configArg?: string;
};
export type ImportStatus = {
  timestamp: string;
  hints?: ImportHints;
  messages: string[];
  warnings: string[];
  error?: string;
};
export type ClusterComputedProperties = {
  fqdn: string;
  autoDetectIpAddress?: string;
  namespaceId: string;
  hasFailingHealthChecks: boolean;
  minReplicaVersion?: string;
  domain: string;
  onboardingStatus?: string;
  importStatus?: ImportStatus;
};
export type Cluster = ObjectStandardProperties &
  ClusterProperties &
  ClusterComputedProperties;
export type RefreshTokenProperties = {
  /** API refresh token */
  refreshToken: string;
};
export type CreateClusterResponse = Cluster & RefreshTokenProperties;
export type NamespaceProperties = {
  parentId?: string;
  name: string;
};
export type NamespaceType = 'cluster' | 'root' | 'regular';
export type Namespace = ObjectStandardProperties &
  NamespaceProperties & {
    type: NamespaceType;
  };
export type CreateOrganizationResponse = {
  cluster: CreateClusterResponse;
  namespace: Namespace;
  organization: Organization;
};
export type CreateOrganizationRequest = OrganizationProperties;
export type UpdateOrganizationRequest = OrganizationProperties & {
  ownerUserId?: string;
};
export type ActivityType = 'create' | 'delete' | 'update';
export type UserInfo = {
  id?: string;
} & UserIdentityInfo;
export type EntityType =
  | 'changeset'
  | 'custom_domain'
  | 'domain'
  | 'key_pair'
  | 'namespace'
  | 'organization'
  | 'policy'
  | 'route'
  | 'settings'
  | 'service_account';
export type ActivityLogProperties = {
  activityType: ActivityType;
  applied: {
    at?: string;
    by: UserInfo;
    changesetId?: string;
  };
  entity: {
    type: EntityType;
    id: string;
    data?: {
      [key: string]: any;
    };
  };
  namespace: {
    id?: string;
    name?: string;
  };
  user: UserInfo;
};
export type ActivityLog = ObjectStandardProperties & ActivityLogProperties;
export type ListActivityLogsResponse = ActivityLog[];
export type CreateBillingPortalSessionResponse = {
  url: string;
};
export type CreateBillingPortalSessionRequest = {
  deepLink: '' | 'payment_method';
  returnUrl: string;
};
export type CreateCheckoutSessionResponse = {
  url: string;
};
export type CreateCheckoutSessionRequest = {
  billingEmail?: string;
  cancelUrl: string;
  organizationName?: string;
  successUrl: string;
};
export type CompleteCheckoutSessionRequest = {
  checkoutSessionId: string;
};
export type Invoice = {
  date: string;
  downloadUrl: string;
  currency: string;
  productName: string;
  total: number;
};
export type ListInvoicesResponse = Invoice[];
export type PaymentInformation = {
  billingEmail?: string;
  card?: {
    brand?: string;
    last4?: string;
  };
  paymentMethod?: string;
};
export type SubscriptionStatus =
  | 'active'
  | 'canceled'
  | 'incomplete_expired'
  | 'incomplete'
  | 'not_found'
  | 'past_due'
  | 'paused'
  | 'trialing'
  | 'unpaid';
export type SubscriptionInformation = {
  hasCustomerId: boolean;
  nextInvoiceDate?: string;
  pricePerSeatPerMonthCents?: number;
  status: SubscriptionStatus;
};
export type BillingUsage = {
  admins: number;
  auditors: number;
  members: number;
  owners: number;
  time: string;
  users: number;
};
export type ChangesetStatus =
  | 'pending'
  | 'applying'
  | 'applied'
  | 'failed'
  | 'current'
  | 'rejected';
export type ChangesetProperties = {
  failureMessage?: string;
  namespaceId: string;
  status: ChangesetStatus;
};
export type Changeset = ObjectStandardProperties & ChangesetProperties;
export type ListChangesetsResponse = Changeset[];
export type ActivityLogList = ActivityLog[];
export type CompareChangesetsResponse = {
  startChangeset?: Changeset;
  endChangeset: Changeset;
  entities: ActivityLogList[];
};
export type ListClustersResponse = Cluster[];
export type CreateClusterRequest = ClusterProperties & {
  domain: string;
};
export type ClusterReplicaProperties = {
  hostname: string;
};
export type ClusterReplica = ObjectStandardProperties &
  ClusterReplicaProperties;
export type ListClusterReplicasResponse = ClusterReplica[];
export type ServiceAccountProperties = {
  expiresAt?: string;
  description: string;
  userId: string;
};
export type ServiceAccount = ObjectStandardProperties &
  ServiceAccountProperties;
export type ListServiceAccountsResponse = ServiceAccount[];
export type ServiceAccountToken = {
  token: string;
};
export type CreateServiceAccountResponse = ServiceAccount & ServiceAccountToken;
export type ClusterHealthCheckStatus = 'success' | 'error' | 'warning';
export type ClusterHealthCheck = {
  description: string;
  helpUrl: string;
  hostname?: string;
  status: ClusterHealthCheckStatus;
  updatedAt: string;
};
export type GetClusterHealthResponse = ClusterHealthCheck[];
export type ReRunHealthChecksRequest = object;
export type DistributionValue = {
  count: number;
  mean: number;
  explicitBucketBounds: number[];
  bucketCounts: number[];
};
export type DistributionSum = {
  labels: StringMap;
  distributionValue: DistributionValue;
  unit: string;
};
export type GetDistributionMetricSumResponse = DistributionSum[];
export type DistributionMetricId = 'upstream_request_time';
export type TimeSeriesDataPoint = {
  value: number | null;
  timestamp: string;
};
export type TimeSeries = {
  labels: StringMap;
  points: TimeSeriesDataPoint[];
  unit: string;
};
export type GetDistributionMetricTimeSeriesResponse = TimeSeries[];
export type Percentile = 50 | 95 | 99;
export type GetTimeSeriesResponse = TimeSeries[];
export type TimeSeriesMetricId =
  | 'mau'
  | 'dau'
  | 'upstream_requests'
  | 'upstream_rx_bytes'
  | 'upstream_tx_bytes'
  | 'authz_ok'
  | 'authz_err'
  | 'authz_denied';
export type Sum = {
  labels: StringMap;
  value: number;
  unit: string;
};
export type GetTimeSeriesSumResponse = Sum[];
export type PingClusterResponse = {
  success: boolean;
  /** error code, if success is false.
    possible values are:
    - no_identity: the cluster does not have an identity key yet
    - dns_error: the cluster's DNS record could not be resolved
    - connection_error: the cluster could not be reached within the timeout period
    - invalid_cert: the cluster's certificate is invalid
    - unexpected_response: the cluster returned an unexpected response
    - key_not_found: the cluster's expected identity key could not be found
     */
  errorCode?:
    | 'err_cluster_ping_no_identity'
    | 'err_cluster_ping_dns_error'
    | 'err_cluster_ping_connection_error'
    | 'err_cluster_ping_invalid_cert'
    | 'err_cluster_ping_unexpected_response'
    | 'err_cluster_ping_key_not_found';
  errorMessage?: string;
};
export type DnsLookupFamily =
  | 'V4_ONLY'
  | 'V6_ONLY'
  | 'V4_PREFERRED'
  | 'AUTO'
  | 'ALL';
export type Duration = string;
export type SettingsProperties = {
  /** Sets the global logging level for Pomerium. Only logs of the desired level and above will be logged. */
  logLevel: string;
  /** Sets the logging level for the Pomerium Proxy service access logs. Only logs of the desired level and above will be logged. */
  proxyLogLevel?: string;
  /** Specifies the IP Address and Port to serve HTTP requests from. If empty, `:443` is used. */
  address: string;
  /** Sets the DNS IP address resolution policy. */
  dnsLookupFamily: DnsLookupFamily;
  /** Specifies the IP Address and Port to redirect HTTP to HTTPS traffic on. If unset, no redirect server is started. */
  httpRedirectAddr?: string;
  /** Sets the amount of time for the entire request stream to be received from the client. */
  timeoutRead: Duration;
  /** Sets the max stream duration is the maximum time that a stream’s lifetime will span. */
  timeoutWrite: Duration;
  /** Sets the idle timeout is the time at which a downstream or upstream connection will be terminated if there are no active streams. */
  timeoutIdle: Duration;
  /** Sets the name of the session cookie sent to clients. */
  cookieName: string;
  /** Sets the secret used to encrypt and sign session cookies. If you don't provide a cookie secret, Pomerium will generate one for you. */
  cookieSecret?: string;
  /** Sets the scope of session cookies issued by Pomerium. If you specify the domain explicitly, then subdomains would also be included. */
  cookieDomain?: string;
  /** If true, this setting forbids JavaScript from accessing the cookie. */
  cookieHttpOnly: boolean;
  /** Sets the lifetime of session cookies. After this interval, users must reauthenticate. */
  cookieExpire: Duration;
  /** Sets the SameSite option for cookies, which determines whether or not a cookie is sent with cross-site requests. */
  cookieSameSite?: string;
  /** ID of CA's public and private key pair. */
  certificateAuthorityKeyPairId?: string;
  /** Specifies a mapping of HTTP Headers added globally to all managed routes and Pomerium's Authenticate Service. */
  setResponseHeaders?: StringMap;
  /** Pass specific user session data to upstream applications as unsigned HTTP request headers. */
  jwtClaimsHeaders?: StringMap;
  /** The default timeout applied to a proxied route when no timeout key is specified by the policy. */
  defaultUpstreamTimeout: Duration;
  /** Exposes a Prometheus endpoint on the specified port. */
  metricsAddress?: string;
  /** The name of the tracing provider (for example, Jaeger or Zipkin). */
  tracingProvider?: string;
  /** Percentage of requests to sample in decimal notation. The default is 0.0001, or .01%. */
  tracingSampleRate: number;
  /** The `host:port` address of the Datadog Trace Agent. Defaults to `localhost:8126`. */
  tracingDatadogAddress?: string;
  /** The URL to the Jaeger HTTP Thrift collector. */
  tracingJaegerCollectorEndpoint?: string;
  /** The address of the `jaeger-agent` where you send Spans to. */
  tracingJaegerAgentEndpoint?: string;
  /** The URL to the Zipkin HTTP endpoint. */
  tracingZipkinEndpoint?: string;
  /** Key pair ID of the downstream client CA. If set, requires mTLS for incoming requests. */
  downstreamMtlsCaKeyPairId?: string;
  /** Specifies the Service Account credentials to support GCP's Authorization Header format. */
  googleCloudServerlessAuthenticationServiceAccount?: string;
  /** If true, the incoming X-Forwarded-For HTTP header would not be modified. */
  skipXffAppend: boolean;
  /** The databroker storage connection string. */
  databrokerStorageConnection?: string;
  /** Controls which fields are included in the access logs. */
  accessLogFields?: string[];
  /** Controls which fields are included in the authorize logs. */
  authorizeLogFields?: string[];
  passIdentityHeaders: boolean;
  autoApplyChangesets: boolean;
  /** Specifies the URL to use for the authenticate service, if not using the Hosted Authenticate Service. (This URL should resolve to your Pomerium deployment.) */
  authenticateServiceUrl?: string;
  /** Identity provider type, if not using the Hosted Authenticate Service. */
  identityProvider?: IdentityProviderType;
  /** Identity provider client ID, if not using the Hosted Authenticate Service. */
  identityProviderClientId?: string;
  /** Identity provider client secret, if not using the Hosted Authenticate Service. */
  identityProviderClientSecret?: string;
  /** Identity provider request params, if not using the Hosted Authenticate Service. */
  identityProviderRequestParams?: StringMap;
  /** Identity provider scopes, if not using the Hosted Authenticate Service. */
  identityProviderScopes?: StringList;
  /** Identity provider URL, if not using the Hosted Authenticate Service. (This is required only for certain identity providers types.) */
  identityProviderUrl?: string;
};
export type Settings = ObjectStandardProperties & SettingsProperties;
export type JsonPatchOperation = {
  op: 'add' | 'remove' | 'replace' | 'copy' | 'move' | 'test';
  path: string;
  value?: any;
  from?: string;
};
export type JsonPatch = JsonPatchOperation[];
export type CustomDomainProperties = {
  clusterId: string;
  domainName: string;
};
export type CustomDomainComputedProperties = {
  lastError?: string;
  keyPairId?: string;
  expiresAt?: string;
};
export type CustomDomain = ObjectStandardProperties &
  CustomDomainProperties &
  CustomDomainComputedProperties;
export type ListCustomDomainsResponse = CustomDomain[];
export type OrganizationInviteProperties = {
  email: string;
  role: OrganizationRole;
};
export type OrganizationInvite = ObjectStandardProperties &
  OrganizationInviteProperties;
export type ListOrganizationInvitesResponse = OrganizationInvite[];
export type CreateOrganizationInviteResponse = OrganizationInvite[];
export type CreateOrganizationInviteRequest = {
  emails: string[];
  role: OrganizationRole;
};
export type CertificateName = {
  country: string[];
  organization: string[];
  organizationalUnit: string[];
  locality: string[];
  province: string[];
  streetAddress: string[];
  postalCode: string[];
  serialNumber: string;
  commonName: string;
};
export type CertificateKeyUsage = {
  digitalSignature: boolean;
  contentCommitment: boolean;
  keyEncipherment: boolean;
  dataEncipherment: boolean;
  keyAgreement: boolean;
  certSign: boolean;
  crlSign: boolean;
  encipherOnly: boolean;
  decipherOnly: boolean;
};
export type CertificateExtKeyUsage = {
  any: boolean;
  serverAuth: boolean;
  clientAuth: boolean;
  codeSigning: boolean;
  emailProtection: boolean;
  ipsecEndSystem: boolean;
  ipsecTunnel: boolean;
  ipsecUser: boolean;
  timeStamping: boolean;
  ocspSigning: boolean;
  microsoftServerGatedCrypto: boolean;
  netscapeServerGatedCrypto: boolean;
  microsoftCommercialCodeSigning: boolean;
  microsoftKernelCodeSigning: boolean;
};
export type CertificateInfo = {
  version: number;
  serial: string;
  issuer: CertificateName;
  subject: CertificateName;
  notBefore: string;
  notAfter: string;
  keyUsage: CertificateKeyUsage;
  extKeyUsage: CertificateExtKeyUsage;
  dnsNames: string[];
  emailAddresses: string[];
  ipAddresses: string[];
  uris: string[];
  permittedDnsDomainsCritical: boolean;
  permittedDnsDomains: string[];
  excludedDnsDomains: string[];
  permittedIpRanges: string[];
  excludedIpRanges: string[];
  permittedEmailAddresses: string[];
  excludedEmailAddresses: string[];
  permittedUriDomains: string[];
  excludedUriDomains: string[];
};
export type KeyPairProperties = {
  namespaceId: string;
  certificate?: string;
  name?: string;
};
export type KeyPairOrigin = 'system' | 'user';
export type KeyPairStatus = 'pending' | 'ready';
export type KeyPairComputedProperties = {
  hasKey: boolean;
  origin: KeyPairOrigin;
  status: KeyPairStatus;
};
export type KeyPair = ObjectStandardProperties &
  KeyPairProperties &
  KeyPairComputedProperties;
export type KeyPairWithCertificateInfo = {
  certificateInfo?: CertificateInfo;
} & KeyPair;
export type ListKeyPairsResponse = KeyPairWithCertificateInfo[];
export type KeyPairWithKeyProperties = {
  key?: string;
} & KeyPairProperties;
export type NamespaceRole = 'admin' | 'manager' | 'viewer';
export type NamespaceWithRole = Namespace & {
  role: NamespaceRole;
};
export type ListNamespacesResponse = NamespaceWithRole[];
export type EntityName = string;
export type PplCriteria = {
  [key: string]: any;
};
export type PplRuleBody = {
  and?: PplCriteria[];
  or?: PplCriteria[];
  not?: PplCriteria[];
  nor?: PplCriteria[];
};
export type PplRule = {
  allow?: PplRuleBody;
  deny?: PplRuleBody;
};
export type PolicyProperties = {
  namespaceId: string;
  name: EntityName;
  enforced: boolean;
  ppl: PplRule | PplRule[];
  description: string;
  explanation: string;
  remediation: string;
};
export type EntityIdAndName = {
  id: string;
  name: string;
};
export type PolicyComputedProperties = {
  routes: EntityIdAndName[];
  enforcedRoutes: EntityIdAndName[];
};
export type Policy = ObjectStandardProperties &
  PolicyProperties &
  PolicyComputedProperties;
export type ListPoliciesResponse = Policy[];
export type RouteDirectResponse = {
  status: number;
  body: string;
};
export type RouteRewriteHeader = {
  header: string;
  matcher: {
    prefix: string;
  };
  value: string;
};
export type RouteRedirect = {
  httpsRedirect?: boolean;
  schemeRedirect?: string;
  hostRedirect?: string;
  portRedirect?: number;
  pathRedirect?: string;
  prefixRewrite?: string;
  responseCode?: number;
  stripQuery?: boolean;
};
export type RouteCommonHealthCheckProperties = {
  timeout: Duration;
  interval: Duration;
  unhealthyThreshold: number;
  healthyThreshold: number;
};
export type IntegerRange = {
  start: number;
  end: number;
};
export type CodecClientType = 'http1' | 'http2';
export type RouteHttpHealthCheck = RouteCommonHealthCheckProperties & {
  type: 'http';
  host?: string;
  path: string;
  expectedStatuses?: IntegerRange[];
  codecClientType?: CodecClientType;
};
export type Hex = string;
export type RouteTcpHealthCheck = RouteCommonHealthCheckProperties & {
  type: 'tcp';
  send?: Hex;
  receive?: Hex[];
};
export type RouteGrpcHealthCheck = RouteCommonHealthCheckProperties & {
  type: 'grpc';
  serviceName?: string;
  authority?: string;
};
export type RouteHealthCheck =
  | ({
      type: 'http';
    } & RouteHttpHealthCheck)
  | ({
      type: 'tcp';
    } & RouteTcpHealthCheck)
  | ({
      type: 'grpc';
    } & RouteGrpcHealthCheck);
export type RouteLoadBalancingPolicy =
  | 'round_robin'
  | 'least_request'
  | 'ring_hash'
  | 'random'
  | 'maglev';
export type RouteProperties = {
  namespaceId: string;
  name: EntityName;
  from: string;
  to?: string[];
  response?: RouteDirectResponse;
  prefix?: string;
  path?: string;
  regex?: string;
  prefixRewrite?: string;
  regexRewritePattern?: string;
  regexRewriteSubstitution?: string;
  hostRewrite?: string;
  hostRewriteHeader?: string;
  hostPathRegexRewritePattern?: string;
  hostPathRegexRewriteSubstitution?: string;
  regexPriorityOrder?: number;
  timeout?: Duration;
  idleTimeout?: Duration;
  allowWebsockets: boolean;
  allowSpdy: boolean;
  tlsSkipVerify: boolean;
  tlsUpstreamServerName?: string;
  tlsDownstreamServerName?: string;
  tlsCustomCaKeyPairId?: string;
  tlsClientKeyPairId?: string;
  tlsDownstreamClientCaKeyPairId?: string;
  tlsUpstreamAllowRenegotiation: boolean;
  setRequestHeaders?: StringMap;
  setResponseHeaders?: StringMap;
  removeRequestHeaders?: string[];
  rewriteResponseHeaders?: RouteRewriteHeader[];
  preserveHostHeader: boolean;
  passIdentityHeaders?: boolean;
  kubernetesServiceAccountToken?: string;
  redirect?: RouteRedirect;
  enableGoogleCloudServerlessAuthentication: boolean;
  showErrorDetails: boolean;
  healthCheck?: RouteHealthCheck;
  loadBalancingPolicy?: RouteLoadBalancingPolicy;
  policyIds: string[];
};
export type RouteComputedProperties = {
  enforcedPolicies: EntityIdAndName[];
  enforcedPolicyIds: string[];
  policies: EntityIdAndName[];
};
export type Route = ObjectStandardProperties &
  RouteProperties &
  RouteComputedProperties;
export type ListRoutesResponse = Route[];
export type UserWithOrganizationRoleProperties = {
  organizationRole: OrganizationRole;
};
export type UserWithOrganizationRole = User &
  UserWithOrganizationRoleProperties;
export type ListUsersInOrganizationResponse = UserWithOrganizationRole[];
export type CreateApiAccessUserResponse = User & RefreshTokenProperties;
export type CreateApiAccessUserRequest = {
  /** Freetext user name */
  name: string;
  role?: OrganizationRole;
};
export type GetTokenResponse = {
  idToken: string;
  expiresInSeconds: string;
};
export type GetVersionResponse = {
  version: string;
};
export const {
  useGenerateSubdomainNameQuery,
  useDeleteCurrentUserMutation,
  useUpdateCurrentUserInfoMutation,
  useListUserInvitationsQuery,
  useAcceptInvitationMutation,
  useRejectInvitationMutation,
  useCheckIdentityProviderSettingsMutation,
  useListDefaultTemplatesQuery,
  useStartOnboardingMutation,
  useConfigureOnboardingMutation,
  useCompleteOnboardingMutation,
  useListOrganizationsQuery,
  useCreateOrganizationMutation,
  useDeleteOrganizationMutation,
  useGetOrganizationQuery,
  useUpdateOrganizationMutation,
  useListActivityLogsQuery,
  useCreateBillingPortalSessionMutation,
  useCreateCheckoutSessionMutation,
  useCompleteCheckoutSessionMutation,
  useListInvoicesQuery,
  useGetPaymentInformationQuery,
  useGetSubscriptionInformationQuery,
  useGetBillingUsageQuery,
  useListChangesetsQuery,
  useCompareChangesetsQuery,
  useApplyChangesetMutation,
  useListClustersQuery,
  useCreateClusterMutation,
  useDeleteClusterMutation,
  useGetClusterQuery,
  useUpdateClusterMutation,
  useListClusterReplicasQuery,
  useListServiceAccountsQuery,
  useCreateServiceAccountMutation,
  useDeleteServiceAccountMutation,
  useGetServiceAccountQuery,
  useGetServiceAccountTokenQuery,
  useGetClusterHealthQuery,
  useRunHealthChecksMutation,
  useGetDistributionSumQuery,
  useGetDistributionTimeSeriesQuery,
  useGetTimeSeriesQuery,
  useGetTimeSeriesSumQuery,
  usePingClusterMutation,
  useGetSettingsQuery,
  useUpdateSettingsMutation,
  usePatchSettingsMutation,
  useRotateClusterTokenMutation,
  useListCustomDomainsQuery,
  useAddCustomDomainMutation,
  useDeleteCustomDomainMutation,
  useRetryCustomDomainMutation,
  useListOrganizationInvitesQuery,
  useCreateOrganizationInviteMutation,
  useDeleteOrganizationInviteMutation,
  useListKeyPairsQuery,
  useCreateKeyPairMutation,
  useDeleteKeyPairMutation,
  useGetKeyPairQuery,
  useUpdateKeyPairMutation,
  useLeaveOrganizationMutation,
  useListNamespacesQuery,
  useListPoliciesQuery,
  useCreatePolicyMutation,
  useDeletePolicyMutation,
  useGetPolicyQuery,
  useUpdatePolicyMutation,
  useListRoutesQuery,
  useCreateRouteMutation,
  useDeleteRouteMutation,
  useGetRouteQuery,
  useUpdateRouteMutation,
  useGetRouteCertificatesQuery,
  useListUsersInOrganizationQuery,
  useCreateApiAccessUserMutation,
  useRemoveUserFromOrganizationMutation,
  useRenewApiUserRefreshTokenMutation,
  useAuthorizeNotificationsMutation,
  useGetIdTokenMutation,
  useGetVersionQuery
} = injectedRtkApi;
